import React from "react";
import Recipes from "../Recipes/Recipes";
import { UserContext } from "../../context/UserContext";
import { Link,withRouter } from "react-router-dom";
import { FaSearch } from "react-icons/fa";

import axios from "axios";
import errorHandler from "../../hooks/errorHandler";
const config = require("../../config.json");


 class Category extends React.Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            cat: 0,
            search: "",
        };
        this.categoryClick = this.categoryClick.bind(this);
        this.searchChangeHandler = this.searchChangeHandler.bind(this);
        this.goSearchHanlder = this.goSearchHanlder.bind(this);
    }
    categoryClick(e) {
        // e.preventDefault();
        const key = e.target.getAttribute("data-key");
        const items = this.state.items;
        const catFound = items.find((c) => Number(c.id) === Number(key));

        //console.log("Found Category:",catName);
        this.setState({ ...this.state, cat: key });

        this.props.data.setCategory({ catId: key, catName: catFound.name });
    }
    searchChangeHandler(e) {
        this.setState({ ...this.state, search: e.target.value });
    }
    goSearchHanlder(e) {
        e.preventDefault();
        if (this.state.search !== "") {
            this.props.history.push(`/recipe?search=${this.state.search}`);
           // window.location = `/recipe?search=${this.state.search}`;
        } else {
            console.log("Nothing to search");
        }
    }
    showRecipes(catId) {
        if (parseInt(this.state.cat) === parseInt(catId)) {
            return <Recipes catId={this.state.cat} />;
        }
    }
    async componentDidMount() {
   

     
        const options = {
            headers: { Authorization: `Bearer ${this.context.token}` }
        };

        try {
            const resp = await axios.get(`${config.api_url}categories`, options);
           
         
            const data = resp.data;
            this.setState({
                ...this.state,
                isLoaded: true,
                items: data,
            });
        } catch (e) {
            const err = errorHandler(e);
            console.log("error:", e.message);
            this.setState({
                items: [],
                isLoaded: true,
                error: err
            });
        }
    }
    render() {
        const { error, isLoaded, items } = this.state;
        if (error) {
            if (error === "401") {
                //UNAUTHORIZE, remove user with bad credentials send home to login
                this.props.removeUser();
                this.context = {};
        
                this.props.history.push("/");
                return ;
            }

            return (
                <div className="main">
                    <h3 className="error"> Error: {error} </h3>
                </div>
            );
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            return (
                <div className="toc">
                    <h2>Table of Contents</h2>
                    <ul>
                        {items.map((category, idx) => (
                            <li
                                className="toc-item user"
                                key={category.id}
                                id={"cat" + category.id}
                            >
                                <Link
                                    to={`/category/${category.id}`}
                                    cat1={category.id}
                                    data-key={category.id}
                                    onClick={this.categoryClick}
                                >
                                    {category.name}
                                </Link>
                            </li>
                        ))}
                    </ul>

                    <form onSubmit={this.goSearchHanlder}>
                        <label>
                            <h3>Search for Recipe:</h3>
                            <input
                                name="search"
                                onChange={this.searchChangeHandler}
                            />
                            <button
                                type="submit"
                                disabled={this.state.search === ""}
                            >
                                <span className="icon-container">
                                    <FaSearch />
                                </span>
                                Search
                            </button>
                        </label>
                    </form>
                   
                </div>
            );
        }
    }
}
export default withRouter(Category);