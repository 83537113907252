import React from "react";
import { UserContext } from "../../context/UserContext";
import { Link } from "react-router-dom";
import { FaHome, FaList, FaPlusCircle, FaQuestion } from "react-icons/fa";
import DropDown from "./DropDown";
import "./menu.scss";
export default class NavMain extends React.Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
       
        this.logOut = this.logOut.bind(this);

        //this.state = { refresh: false };
        this.state = { search: "" };
    }
    logOut(e) {
        e.preventDefault();
        this.props.removeUser();
       
        this.context = {};
        window.location = "/";
    }

    render() {
        const shopping = `/shoppinglist/${this.context.id}`;

        return (
            <nav className="clearfix">
                <ul>
                    <li className="nav-left" title="Table of Contents">
                        <Link to="/category">
                            <FaHome />
                            <span className="menuLabel">Table of Contents</span>
                        </Link>
                    </li>
                    <li className="nav-left" title="Add a recipe">
                        <Link to="/recipe/create">
                            <FaPlusCircle />
                            <span className="menuLabel">Add a Recipe</span>
                        </Link>
                    </li>
                    <li className="nav-left" title="Shopping List">
                        <Link to={shopping}>
                            <FaList />
                            <span className="menuLabel">Shopping List</span>
                        </Link>
                    </li>

                    {/* <li className="nav-right">
                        <a href="/" onClick={(e) => this.logOut(e)}>
                            {" "}
                            Logout
                        </a>
                    
                    </li>
                    <li className="nav-right">
                    <Link to="/user/profile">Profile</Link>
                    </li> */}
                    <li className="nav-right" title = "User Menu">
                        {" "}
                        <DropDown {...this.props} />
                    </li>
                    <li className="nav-right" title="Help">
                        <Link to="/help">
                            <FaQuestion />
                            <span className="menuLabel">Help</span>{" "}
                        </Link>
                    </li>
                </ul>
            </nav>
        );
    }
}
