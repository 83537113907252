import "./user.scss";
import { useState } from "react";
export default function Forgot() {
    const config = require("../../config.json");
    const [error, setError] = useState("");
    const onForgotSubmit = async (e) => {
        e.preventDefault();
        const { email } = e.target.elements;
        const url = `${config.api_url}reset/forgot/`;
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ email: email.value }),
        };
        try {
            const resp = await fetch(url, options);
            if (!resp.ok) {
                throw new Error(resp.status);
            }
           // const data = await resp.json();
           // Just need statusOK.
            setError("Email has been sent! Check your inbox.");
        } catch (e) {
            setError(
                (err) =>
                    ({
                        400: "Can't find your email address! If you haven't created an account, please create one.",
                        "Failed to fetch":
                            "Sorry, there is a communication error to the server. Please try again later",
                    }[e.message] ||
                    "Sorry, an Unkown error has occured: " + e.message)
            );
            //console.log(e.message);
        }
    };
    return (
        <div className="book">
            <section>
                <h2>Forgot Password</h2>
                <form onSubmit={onForgotSubmit}>
                    <label htmlFor="email">
                        Enter email address for your Recipe Book. We will email
                        you a reset link:
                    </label>
                    <br />
                    <input type="email" name="email" id="email" required />
                    <br />
                    <p className="error">{error}</p>
                    <button name="submit">Reset Password</button>
                </form>
            </section>
        </div>
    );
}
