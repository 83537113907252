import "./blog.scss";
import React from "react";

import BlogItem from "./BlogItem";
class Blog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          items: []
        }
    }
    async componentDidMount() {
        try {
            const resp = await fetch(
                process.env.PUBLIC_URL + `/blogs/index.json`
            );
            if (resp.ok) {
              


                const data = await resp.json();
                // let arr = [];
                // data.forEach(element => {
                //     arr=[...arr,element];
                    
                // });
                
                // console.log(arr);
                console.log(data);
                this.setState({items : data});
                
            }
        } catch (err) {
            console.log(err.message);
        }
    }

    render() { 
        const items = this.state.items;
        console.log(`rendering:${items[0]}`)
        return (
            <div className="helpcard">
            <h2>Worth Cooking Blog</h2>
            {
                this.state.items.map(item => {
        
                    return (
                    <BlogItem blog={item.file} />
                    )
        
             })
            }    
        <div className="spacer100"></div>
    </div>
    )
    }
}
export default Blog;


