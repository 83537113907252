import {useLocation} from "react-router-dom";
import "./QRRecipe.scss"
const QRCode= require('qrcode.react');
function QRRecipe(props) {
    const search = useLocation().search;
    const url = new URLSearchParams(search).get('url');
    console.log(url);
    

    return (
        <section>
            <a href={url} target="_blank" rel="noreferrer"><h2>{url}</h2></a>
            <QRCode value={url} size={256} fgColor="#220044" />,
        </section>
    );
}
export default QRRecipe;
