import React from "react";
import { UserContext } from "../../context/UserContext";
import RecipeForm from "./RecipeForm";
import { withRouter } from "react-router";
import fetchOptions from "../../hooks/fetchOptions";
const config = require("../../config.json");
class EditRecipe extends React.Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.updateRecipe = this.updateRecipe.bind(this);
        this.uploadPhoto = this.uploadPhoto.bind(this);
        this.savePhoto = this.savePhoto.bind(this);
        this.state=({});
    }
    uploadPhoto(event) {
        console.log(event.target.files["0"]);
        this.setState({ uploadPhoto: event.target.files["0"] }, () =>
            console.log("NEW State", this.state)
        );
    }
    async savePhoto(rId) {
        console.log("Photo to Upload:",this.state.uploadPhoto);
        console.log("rId is: ",rId);
        const data = new FormData();

        data.append("file", this.state.uploadPhoto);
        const url = `${config.api_url}photo/${rId}`;
    
        const options = {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${this.context.token}`,
            },
            body: data,
        };
        try{
            const resp = await fetch(url,options);
            if(!resp.ok) {
                throw new Error(resp.statusText);
            }
            const savedPhoto = await resp.json();
            console.log("JSON returned from PHOTO Uploqe");
            console.log(savedPhoto);
            return savedPhoto;
        }
        catch(e) {
            console.log(e);
            return null;
        }

    }
    async updateRecipe(e) {
        e.preventDefault();
        console.log("Recipe Update");
        const rid = this.props.match.params.id;
        
        const photoUploaded = this.state.uploadPhoto
            ? await this.savePhoto(rid)
            : null;
        
        // console.log(e.target['recipe-name'].value);
        // console.log(e.target['category_id'].value);

        const form = {
            name: e.target["name"].value,
            category_id: e.target["category_id"].value,
            url: e.target["url"].value,
            content: e.target['content'].value
        };
        if (photoUploaded) {
            form["photo"] = photoUploaded.file;
        }
        const body = JSON.stringify(form);
        const url = `${config.api_url}recipes/${rid}`;
        const options = fetchOptions({
            method: "PATCH",
            token: this.context.token,
            body: body,
        });
        try {
            const resp = await fetch(url, options);
            if (!resp.ok) {
                throw new Error(resp.statusText);   
            }
            //const data = await resp.json();
           
            this.props.history.goBack();
            // this.props.history.replace(`/recipe/${rid}/edit`,`/category`);
            // window.location=`/recipe/${rid}`;
        } catch (err) {
            console.error(err);
        }
    }

    render() {
        const rid = this.props.match.params.id;
        return (
            <div className="main">
                <h2>Edit Recipe</h2>
                <RecipeForm onSubmit={this.updateRecipe} rid={rid} uploadPhoto={this.uploadPhoto} ></RecipeForm>
            </div>
        );
    }
}
export default withRouter(EditRecipe);
