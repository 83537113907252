import "./blog.scss";
import React from "react";

class BlogItem extends React.Component {
    constructor(props) {
        super(props);
        console.log(props.blog);
        this.state = {data:""};
    }
    componentDidMount() {
         fetch(
            process.env.PUBLIC_URL + `/blogs/${this.props.blog}`
        ).then((resp) => {
            if (resp.ok) {
                //console.log(`${file} is good`);
                resp.text().then((text) =>{
                   // blogHtml = blogHtml + text;
                    this.setState({data:text})
                   // console.log(blogHtml);
                });
               //

            }
        }).catch((err) =>{
            console.log(err.message);

        });

    }
    // function getABlog(file) {
    
    // }
    render() {
        return (
            <span dangerouslySetInnerHTML={{__html: this.state.data}} />
        );
    }
}
export default BlogItem;
