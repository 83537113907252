import React from "react";
import { UserContext } from "../../context/UserContext";
import fetchOptions from "../../hooks/fetchOptions";
const config = require("../../config.json");
class CatSpinner extends React.Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.state = { data: [] };
    }
    async componentDidMount() {
        const options = fetchOptions({
            method: "GET",
            token: this.context.token,
        });
        
        const url = `${config.api_url}categories/`;
        try {
            const resp = await fetch(url, options);
            if (!resp.ok) {
                throw new Error(resp.statusText);
            }
            const data = await resp.json();
            
            this.setState({ data: data });
        } catch (e) {
            console.error(e);
        }
    }
    render() {
        
        if (this.state != null) {
            return (
                <div>
                    <label htmlFor="catid" className="form-field">
                        Category
                    </label>
                    <select name="category_id" id="category_id" value={this.props.catId} onChange={this.props.handleChange}>
                        {this.state.data.map((cat, idx) => (
                            <option value={cat.id} key={cat.id}>{cat.name}</option>
                        ))}
                    </select>
                </div>
            );
        }
    }
}
export default CatSpinner;
