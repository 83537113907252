export default function errorHandler(err) {
   
    if (err.response) {
        console.log("Status",err.response.status);
        if (err.response.status === 401) {
            
            return "Invalid Username or Password";
        }
        if (err.response.status === 400) {
            return "Bad Request";
        }
        if (err.response.status === 404) {
            return "Page Not Found";
        }
        return "Server Error";
    } else if (err.request) {
        return "Cannot connect to Worthcooking Server. (Might be down)";
    } else {
        return "unknown error";
    }
}