import { Link } from "react-router-dom";
import {FaEdit} from "react-icons/fa";
export default function EditButton(props) {

    return (
        <span className="icon-container">
        <Link to ={`/recipe/${props.rid}/edit`}>
            <FaEdit   />
       
        </Link>
        </span>
    );
}