import { FaEdit, FaTrashAlt } from "react-icons/fa";
import "./help.scss";
function Help() {
    return (
        <div className="helpcard">
            
            <h2 class="pen">Help for Web App</h2>
            <h3>Table of Contents</h3>
            <ul>
                <a href="#w_create">
                    <li>Create Account</li>
                </a>
                <a href="#w_login">
                    <li>Logging In</li>
                </a>
                <a href="#w_view">
                    <li>View Recipes</li>
                </a>
                <a href="#w_add">
                    <li>Add Recipes</li>
                </a>
                <a href="#w_edit">
                    <li>Edit Recipes</li>
                </a>
                <a href="#w_delete">
                    <li>Delete Recipe</li>
                </a>
                <a href="#w_shop">
                    <li>Shopping List</li>
                </a>
            </ul>
            <h3 id="w_create">Create Account</h3>
            <p>To create an account, click on Register</p>
            <p>
                Fill out form and hit submit. make sure you repeat the password
                and fill out the Captcha box(Those funny letter things)
            </p>
            <h3 id="w_login">Logging In</h3>

            <p>
                Fill out username and password and hit Submit. If you forget the
                password, please click the link that appears after an incorrect
                password.
            </p>

            <h3 id="w_view">View Recipe</h3>
            <p>
                To View a recipes, click a category tab and then click a recipe.
                To search for a recipe, click the search box on the menu, and
                think click on Search. To return to Table of Content, Click on
                Table of Contents
            </p>
            <p>
                If you have selected a view shared option, shared recipes will
                be silver and not editable
            </p>
            <h3 id="w_add">Add Recipe</h3>
            <p>
                To Add a new recipe press Add new recipe on top of menu. Give a
                name to the recipe and choose an appropate recipe
            </p>
            <p>You have two choices fror the recipe:</p>
            <p>
                To use a recipe on a website, click website radio button and
                paste url of website in the box below
            </p>
            <p>
                If you are writing you are writing you own recipe, check the
                "Write your own" radio button and put the recipe below
            </p>
            <p>
                You can add a photo by hitting browse, and selecting a photo.
                PNG, JPG, GIF recommended, try to keep it around 300x300 pixels
            </p>
            <p>Press Save to save your recipe</p>

            <h3 id="w_edit">Edit Recipe</h3>
            <p>
                To Edit a recipe, click on the edit icon{" "}
                <FaEdit />  This will open
                the editor.{" "}
            </p>
            <p>
                Follow the same instructions for adding a recipe then click Save
                to save the recipe
            </p>
            <h3 id="w_delete">Delete Recipe</h3>
            <p>
                {" "}
                To Delete a recipe click the delete icon{" "}
                <FaTrashAlt /> and then click
                'OK'.
            </p>
            <h3 id="w_shop">Shopping List</h3>
            <p>
                You can now have a shopping list. Click on the Shopping list
                Button on the menu. This will open up the shopping list. You can
                now enter your items for you shopping list. To clear the list,
                press the clear button. to save and close the list, click Save
                and Close
            </p>
            
           
        </div>
        
    );
}
export default Help;
