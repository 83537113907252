import { Link, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useState } from "react";
import config from "../../config.json";
import passwordValidator from "../../hooks/passwordValidator";

function Reset(props) {
    const search = useLocation().search;
    const { register, handleSubmit } = useForm();
    //console.log("Key", search);
    const [error, setError] = useState();
    const [complete, setComplete] = useState(false);
    const resetPassword = async (form) => {
        // e.preventDefault();

        const validator = passwordValidator(
            form.password,
            form.confirm_password
        );
        if (validator !== "") {
            setError(validator);
            return;
        }
        delete form.confirm_password; //don't need to send this
        const url = `${config.api_url}reset${search}`;
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(form),
        };

        //console.log("To be sent:", form);
        //console.log(url);
        try {
            const resp = await fetch(url, options);
            if (!resp.ok) {
                throw new Error(resp.status);
            }
            const data = await resp.json();
            if (data) {
                //alert("Password has been changed");
                setComplete(true);
            }
        } catch (err) {
            //capture 401 and display message
            if (err.message === "401") {
                setError(
                    "Invalid key/uid. Make sure you type the url correctly from the last email."
                );
            } else {
                setError("Server Error");
                console.error(err.message);
            }
        }
    };
    return (
        <div className="book">
            <section>
                <h2>Reset Password</h2>
                {!complete && (
                    <form onSubmit={handleSubmit(resetPassword)}>
                        <div>
                        <label htmlFor="password">
                            Enter new Password
                            <input
                                name="password"
                                type="password"
                                {...register("password")}
                            />
                        </label>
                        </div>
                        <div>
                        <label htmlFor="retype">
                            Retype Password
                            <input
                                name="retype"
                                type="password"
                                {...register("confirm_password")}
                            />
                        </label>
                        </div>
                        <div className="error">{error}</div>
                        <button type="submit">Reset</button>
                    </form>
                )}
                {complete && (
                    <>
                        <h3>Password has been changed</h3>
                        <Link to="/login">Login</Link>
                    </>
                )}
            </section>
        </div>
    );
}

export default Reset;
