import { useLocation } from 'react-router-dom';
export default function Footer() {
   const search = useLocation().search;
   const webview = new URLSearchParams(search).get('webview');
   if (webview)
      return (<></>);
   return (
    <footer>
         <address>&copy; 2021 Worth Consulting</address>
    </footer>
   );
}