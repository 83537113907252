import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useContext } from "react";
import { UserContext } from "../context/UserContext";
const PrivateRoute = ({component : Component, ...rest}) => {
    const auth = useContext(UserContext) || false;
    //console.log("auth:",auth);
    return (
        <Route {...rest} render={props => (
            auth ?
                <Component {...props} {...rest}/>
            : <Redirect to="/login" />
        )} />);
};
export default PrivateRoute