import { useContext, useRef} from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { useDetectOutsideClick } from "./useDetectOutsideClick";
import { FaUser } from "react-icons/fa";
export default function DropDown(props) {
    const dropdownRef = useRef(null);
    const context = useContext(UserContext);
   
    const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
    const onClick = () => setIsActive(!isActive);
    const logOut =(e) => {
        e.preventDefault();
        props.removeUser();
       // this.setState({ refresh: true });
        //this.context = {};
        window.location ="/";
    }
    return (
        <div className="menu-container">
            <button onClick={onClick} className="menu-trigger">
              
              <FaUser />
                <span className="menuLabel">{context.firstName}</span>
            </button>
            <nav
                ref={dropdownRef}
                className={`menu ${isActive ? "active" : "inactive"}`}
            >
                <ul>
                    <li>
                    <Link to="/user/profile">Profile</Link>
                    </li>
                    <li>
                        <a href="/" onClick={logOut}>Log off</a>
                    </li>
                    
                </ul>
            </nav>
        </div>
    );
}
