import axios from "axios";
import errorHandler from "./errorHandler";
const config = require("../config.json");

export default async function loginUser(email, password) {
    const url = `${config.api_url}users/login`;
    const body = { 'email': email, 'password': password };
    const options = {
        headers: {
            "Content-Type": "application/json"
           
        },
    };

    try {
        const res = await axios.post(url, body, options);
        const user = { ...res.data, password: password };
        return user;
    } catch (err) {
        console.error(err);
        const errMsg = errorHandler(err);

        console.log(errMsg);
        throw new Error(errMsg);;
    }
}

