import { useState } from 'react';

export default function useUser() {
  const getUser = () => {
    const userString = localStorage.getItem('user');
    if (userString==="undefined")
      return null;
    
    const user = JSON.parse(userString);
    //  console.log('User is:',user);
    return user
  };

  const [user, setUser] = useState(getUser());

  const saveUser = userData => {
    localStorage.setItem('user', JSON.stringify(userData));
    setUser(userData);
  };
 const removeUser = () => {
    localStorage.removeItem('user') ;
      setUser(null);
    }
 

  return {
    setUser: saveUser,
    user,removeUser: removeUser
  }
}