import { useEffect, useState } from "react";
import fetchOptions from "../../hooks/fetchOptions";
import { useContext } from "react";
import { UserContext } from "../../context/UserContext";

const config = require("../../config.json");
function ShoppingList(props) {
    const context = useContext(UserContext);
    const id = props.match.params.id;
    const [shopping, setShopping] = useState("");
    useEffect(() => {
        async function getShoppingList() {
            const url = `${config.api_url}shopping/${id}`;
            const options = fetchOptions({
                method: "GET",
                token: context.token,
            });
            try {
                const resp = await fetch(url, options);
                if (!resp.ok) {
                    throw new Error(resp.status);
                }
                const data = await resp.json();
                setShopping(data.items);
                //  console.log(data);
            } catch (e) {}
        }
        getShoppingList();
    }, [id, context.token]);

    const handleClear = (e) => {
        e.preventDefault();
        setShopping("");
    };
    const handleSave = async (e) => {
        e.preventDefault();
        const urlSave = `${config.api_url}shopping/${id}`;
        const options = fetchOptions({
            method: "PATCH",
            token: context.token,
            body: JSON.stringify({ items: shopping }),
        });
        try {
            const resp = await fetch(urlSave, options);
            if (!resp.ok) throw new Error(resp.status);
            //console.log("Shopping List has been saved!");
            alert("Shopping List has been saved");
        } catch (e) {
            console.log("Error:", e.message);
        }
    };
    const handleChange = (e) => {
        setShopping(e.target.value);
    };
    return (
        <div className="main">
            <h2>Shopping List</h2>
            <form>
                <textarea value={shopping} onChange={handleChange}></textarea>
                <div>
                    <button onClick={handleSave}>Save</button>{" "}
                    <button onClick={handleClear}>Clear</button>
                </div>
            </form>
        </div>
    );
}
export default ShoppingList;
