import React, { useState } from "react";
import { Link } from "react-router-dom";
//import ReCaptchaV2 from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import "./user.scss";
const config = require("../../config.json");
//const recaptchaRef = React.createRef();

export default function Register(props) {
    const { register, handleSubmit } = useForm();

    const [errors, setErrors] = useState("");
    const [complete,setComplete] = useState(false);
    

    // const onReCaptchaChange = (value) => {
    //     console.log("Captcha value:", value);
    // };
    const clearErrors = (e) => {
        setErrors("");
    };
    const regSubmit = async (data) => {
        //e.preventDefault();
       
        if (data.password_confirm !== data.password) {
            //alert("Passwords don't match!");
            setErrors("Passwords Don't match");
            return;
        }
        // if(recaptchaRef.current.getValue()===null) {
        //     console.log("Sorry Robots don't need recipes");
        //     return;
        // }
        //if(true) return;
        const url = `${config.api_url}users/register`;

        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
            // mode: "no-cors",
        };
        try {
            const resp = await fetch(url, options);
            
            if (!resp.ok) {
                throw new Error(resp.status);
            }
            //const data = await resp.json();
            setComplete(true);
           
            //hide form

        } catch (e) {
            if (e.message === "400") {
                // alert("Sorry, the email already has been registered");
                setErrors("Sorry, the email already has been registered");
            }
            console.error("Error:", e.message);
        }
    };

    return (
        <div className="book">
            <section>
                <h2>Register User</h2>
                {!complete && (
                <form onSubmit={handleSubmit(regSubmit)} id="frmReg">
                    <label>
                        <h5>Email:</h5>
                        <input
                            type="email"
                            name="email"
                            onFocus={clearErrors}
                            required
                            {...register("email")}
                        />
                    </label>
                    <label>
                        <h5>First Name:</h5>
                        <input
                            type="string"
                            name="first_name"
                            required
                            {...register("first_name")}
                        />
                    </label>
                    <label>
                        <h5>Last Name:</h5>
                        <input
                            type="string"
                            name="last_name"
                            required
                            {...register("last_name")}
                        />
                    </label>

                    <label>
                        <h5>Password:</h5>
                        <input
                            type="password"
                            name="password"
                            onFocus={clearErrors}
                            required
                            {...register("password")}
                        />
                    </label>
                    <label >
                        <h5>Repeat Password:</h5>
                        <input
                            type="password"
                            name="password_repeat"
                            onFocus={clearErrors}
                            required
                            {...register("password_confirm")}
                        />
                    </label>
                    <label htmlFor="view_shared" className="checkfix">
                        <h5>View Shared Recipes</h5>
                        <input

                        type="checkbox"
                        name="view_shared"
                        id="view_shared"
                        {...register("view_shared")}
                        />
                    </label>
                    {/* <ReCaptchaV2 sitekey={config.recaptcha} onChange={onReCaptchaChange} ref={recaptchaRef} />  */}
                    <br />
                    <p className="error">{errors}</p>
                    <div>
                        <button>Register</button>
                    </div>
                </form>)}
                {complete && (
                    <>
                        <h3>Your account has been created</h3>
                        <Link to="/login">Login</Link>
                    </>
                )}
            </section>
        </div>
    );
}
