import React, { useState } from "react";
//import HttpError from "../ErrorHandling/HttpError";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import "./user.scss";
import playLogo from "../../images/google-play-badge.png";
import loginUser from "../../hooks/loginUser";
//const config = require("../../config.json");


// async function loginUserOld(credentials) {
//     console.log("API:", config.api_url);
//     return fetch(`${config.api_url}users/login`, {
//         method: "POST",
//         headers: {
//             "Content-Type": "application/json",
//         },
//         body: JSON.stringify(credentials),
//     });
// }


export default function Login(props) {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [errMsg, setErrMsg] = useState(" ");
    const history = useHistory();
    // console.log("Login: ", props);
    //const setUser = props.data.setUser();
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const data = await loginUser(email,password);
            //const resp = await loginUser({email, password });
            // if (!resp.ok) {
            //     throw new Error(resp.status);
            // }
           // const data = await resp.json();
            const user = {...data, 'password' : password};
           //console.log("User To be Saved:", user);
            props.data.setUser(user);
            //window.location = "/category";
            history.push("/category");
        } catch (err) {
            console.log(err.message);
            setErrMsg(err.message);


            // setErrMsg(
            //     {
            //         401: "Invalid Email or Password",
            //         500: "Server Error! (Uh-Oh!)",
            //         "Failed to fetch":
            //             "Sorry, there is a communication error to the server. Please try again later",
            //     }[err.message] || "Unknown error"
            // );
        }
    };
    return (
        <div className="book">
            <section>
                <form onSubmit={handleSubmit}>
                    <h2>Please Login</h2>
                    <label>
                        <h4>Email:</h4>
                        <input
                            type="text"
                            name="email"
                            autoComplete="email"
                            onChange={(e) => {
                                setEmail(e.target.value);
                                setErrMsg(" ");
                            }}
                        />
                    </label>
                    <label>
                        <h4>Password:</h4>
                        <input
                            type="password"
                            name="password"
                            autoComplete="password"
                            onChange={(e) => {
                                setPassword(e.target.value);
                                setErrMsg(" ");
                            }}
                        />
                    </label>
                    <div>
                        <button type="submit">Login</button>
                    </div>
                </form>
                <h6 className="error">{errMsg}</h6>
                <div className="tray">
                    <Link to="/register">New User</Link>
                    <Link to="/user/forgot">Forgot Your Password</Link>
                </div>
            </section>
            <div className="storeTray>">
                <a
                    className="playLink"
                    href="https://play.google.com/store/apps/details?id=ca.worthconsulting.worthcooking"
                    rel="noreferrer"
                    target="_blank"
                >
                    <img src={playLogo} className="playStore" alt="PlayStore" />
                </a>
            </div>
        </div>
    );
}
