export default function fetchOptions({ method, token, body }) {
    const option = {
        method: method,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            
        },
    };
    if (body) {
        option.body = body;
    }
    return option;
}
