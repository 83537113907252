import React from "react";
import { UserContext } from "../../context/UserContext";
import { withRouter } from "react-router";
import fetchOptions from "../../hooks/fetchOptions";
import plate from "../../images/plate.jpg";
import EditButton from "./EditButton";
import DeleteButton from "./DeleteButton";
import { Link } from "react-router-dom";

import { FaQrcode } from "react-icons/fa";


const config = require("../../config.json");

class Recipe extends React.Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.state = { recipe: {} };
        this.photoError = this.photoError.bind(this);
        this.deleteRecipe = this.deleteRecipe.bind(this);
        this.checkIframe = this.checkIframe.bind(this);
       
    }
    checkIframe()   {
        const that = document.getElementById('webRecipe');
        
        try{
            
           let doc = that.contentWindow.document;
          
           if(doc) {
            //that.style.display="block";
            console.log(doc);
            console.log("Iframe should be good")
           }
           else {
            console.log("Iframe cannot be displayed")
           }
         }
         
         catch(err){
            //err:SecurityError: Blocked a frame with origin "http://*********" from accessing a cross-origin frame.
            console.log('err:'+err);
            // console.log(that.src);
            // if(!err.message.indexOf("localhost")) {
            // that.style.display="none";
            // }
        }
    }
    async componentDidMount() {
        const id = this.props.match.params.id;
      
        //this.setState({id:id});

        const options = fetchOptions({
            method: "GET",
            token: this.context.token,
        });

        const url = `${config.api_url}recipes/${id}`;
        try {
           
            const response = await fetch(url, options);
            
            if (!response.ok) {
                throw Error(response.statusText);
            }
            const data = await response.json();

            this.setState({ recipe: data });
           
        } catch (e) {
            console.error("Error:", e);
        }
    }
    photoError(e) {
        e.preventDefault();
        e.target.src = plate;
    }
    deleteRecipe(rId) {
        this.props.history.goBack();
    }

    async checkLink(link) {}
    render() {
        const recipe = this.state.recipe;
        let showPhoto = plate;
        if (recipe.photo) {
            showPhoto = `${config.api_url}photos/${recipe.photo}`;
        }
        let web = "";
        
        const qrLink = (
            <span className="icon-container">
            <Link to={`/qrcode?url=${recipe.url}`}>
                <FaQrcode  />
                {/* <img src={qrIcon} alt="QR" className="qricon" />{" "} */}
            </Link>
            </span>
        );
        //URL THAT CAN BE DISPLAYED (IFRAME OR LINK)
        if (recipe.url !== undefined && recipe.url !== "" ) {
            console.log("recipe url is:",recipe.url);
            web = (
                <>
                    <iframe  id="webRecipe"  src={recipe.url} title="Recipe Webpage"
                    onLoad={this.checkIframe} loading="lazy"
                     />
                    
                        <a href={recipe.url} target="_blank" rel="noreferrer">
                        <h5 className="linksite">
                            {recipe.url}
                        </h5>
                        </a>
                  
                    {qrLink}
                    </>
            );
            
         
        } 
        let toolBox = "";
        
        if(recipe.user_id===this.context.id) {
            toolBox = (<span className="glyph-bottom">
            <EditButton  rid={recipe.id} />
            <DeleteButton
                rId={recipe.id}
                rName={recipe.name}
                deleteRecipe={this.deleteRecipe}
            />
        </span>);
        }
        return (
            <div className="main">
                <img
                    src={showPhoto}
                    alt="Food"
                    className="photo"
                    onError={this.photoError}
                />
                <h2>Recipe {recipe.name}</h2>
                <h3>Category: {recipe["Category.name"]}</h3>

                <pre> {recipe.content}</pre>
                {web}
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6875642631888242"
             crossorigin="anonymous"></script>
                {toolBox}
                
            </div>
        );
    }
}
export default withRouter(Recipe); //add match to props
