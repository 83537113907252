import React from "react";
import { UserContext } from "../../context/UserContext";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import EditButton from "./EditButton";
import DeleteButton from "./DeleteButton";
import fetchOptions from "../../hooks/fetchOptions";
import queryString from "query-string";
const config = require("../../config.json");

class Recipes extends React.Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.state = { data: [] };
        this.deleteRecipe = this.deleteRecipe.bind(this);
    }
    async componentDidMount() {
        const catId = this.props.match.params.catid;
        const searchQuery = this.props.location.search;
        const query = searchQuery ? searchQuery : `?catid=${catId}`;
        this.context.catId = catId;
        const options = fetchOptions({
            method: "GET",
            token: this.context.token,
        });
        const url = `${config.api_url}recipes${query}`;
       
        try {
            const response = await fetch(url, options);
            if (!response.ok) {
                throw Error(response.statusText);
            }
            const data = await response.json();
            // console.log ("Data:",data);
            this.setState({
                data: data,
                category: this.props.data.category.catName,
            });
        } catch (error) {
            console.log(error);
        }
    }

    deleteRecipe(rid) {
        const filteredRecipes = this.state.data.filter(
            (recipe) => recipe.id !== rid
        );
        this.setState({
            data: filteredRecipes,
            category: this.props.data.category.catName,
        });
    }

    render() {
        const header = this.props.location.search
            ? queryString.parse(this.props.location.search).search
            : this.props.data.category.catName;

        return (
            <div className="toc">
                <h2>Recipes for {header}</h2>
                <ul>
                    {this.state.data.map((recipe, idx) => {
                       
                        const sharedToken =
                            recipe.user_id === this.context.id
                                ? "user"
                                : "shared";
                        const toolBox =
                            sharedToken === "user" ? (
                                <span className="glyph-tray">
                                    <EditButton rid={recipe.id} />
                                    <DeleteButton
                                        rId={recipe.id}
                                        rName={recipe.name}
                                        deleteRecipe={this.deleteRecipe}
                                    />
                                </span>
                            ) : (
                                ""
                            );

                        return (
                            <li key={recipe.id} className={sharedToken}>
                                <Link to={`/recipe/${recipe.id}`}>
                                    {recipe.name}
                                </Link>

                                {toolBox}
                            </li>
                        );
                    })}
                </ul>
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6875642631888242"
             crossorigin="anonymous"></script>
            </div>
        );
    }
}
export default withRouter(Recipes);
