


import  {UserContext} from '../../context/UserContext';
import {useContext} from 'react';
import { useLocation } from 'react-router-dom';
import NavMain from './NavMain.jsx';
function WCHeader({removeUser}) {
  const con =useContext(UserContext)
  let nav=null;
  const search = useLocation().search;
  const webview = new URLSearchParams(search).get('webview');
   if (webview)
      return (<div className='voidheader' />);
  if (con) {
    //console.log("User is Logged in as:",con);
    nav=<NavMain removeUser={removeUser} />
  }
  return (
    
      <header>      
        	<h1>WORTH COOKING</h1>
          <h2>Your Personal Recipe Book</h2>
          {nav}
      </header>
   
  );
}
export default WCHeader;