import { useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import {FaTrashAlt} from "react-icons/fa"
import config from "../../config.json";
import fetchOptions from "../../hooks/fetchOptions";
export default function DeleteButton(props) {
    const context = useContext(UserContext);
    const handleDelete = async (event) => {
        event.preventDefault();
       
        const confirm = window.confirm(
            `Do you want to to delete the recipe: ${props.rName}?`
        );
        if (!confirm) return;
        const url = `${config.api_url}recipes/${props.rId}`;
        const options = fetchOptions({
            method: "DELETE",
            token: context.token,
        });
        try {
            const resp = await fetch(url, options);
            if (!resp.ok) {
                throw new Error(resp.status);
            }
            props.deleteRecipe(props.rId);
        } catch (err) {
            console.error(err);
        }
    };
    return (
        <span className="icon-container">
            <Link to={`/recipe/${props.rid}/delete`} onClick={handleDelete}>
                <FaTrashAlt   />
             
            </Link>
        </span>
    );
}
