//db.categimport logo from './logo.svg';

import "../styles/App.scss";
import { useState } from "react";
import WCHeader from "./Partials/WCHeader.jsx";
import Footer from "./Partials/Footer.jsx";
import useUser from "../hooks/useUser";

import { UserContext } from "../context/UserContext";
import Routes from "./Routes";

function App() {
    const [category, setCategory] = useState({ catId: 0, catName: "Search" });
    const { user, setUser, removeUser } = useUser();
    

    return (
        <UserContext.Provider value={user}>
            <div className="App">
                <WCHeader removeUser={removeUser} />

                <Routes user={user} category={category} setCategory={setCategory} setUser={setUser} />
            </div>
           
            <Footer />
        </UserContext.Provider>
    );
}

export default App;
