import React from "react";
import RecipeForm from "./RecipeForm";
import { UserContext } from "../../context/UserContext";
import { withRouter } from "react-router";
import fetchOptions from "../../hooks/fetchOptions";
const config = require("../../config.json");
class AddRecipe extends React.Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.saveRecipe = this.saveRecipe.bind(this);
        this.uploadPhoto = this.uploadPhoto.bind(this);
        this.state = { uploadPhoto: {}, recipe: {} };
    }
    buildBody(form) {}
    async savePhoto() {
       // console.log("state:", this.state);
       
        const data = new FormData();

        data.append("file", this.state.uploadPhoto);
        const url = `${config.api_url}photos`;
    
        const options = {
            method: "POST",
            headers: {
                Authorization: `Bearer ${this.context.token}`,
            },
            body: data,
        };
        try {
            const resp = await fetch(url, options);
            if (!resp.ok) {
                throw new Error(resp.statusText);
            }
            const photoFile = await resp.json();
            return photoFile;
        } catch (e) {
            console.error("Error:", e);
            return null;
        }
    }
    uploadPhoto(event) {
        //console.log(event.target.files["0"]);
        this.setState({ uploadPhoto: event.target.files["0"] }        );

        //upload photo
        //set state of photo
        //update photo?
    }
    async saveRecipe(e) {
        e.preventDefault();
       
        const photoUploaded = this.state.uploadPhoto
            ? await this.savePhoto()
            : null;
        // if (photoUploaded) {
        //     console.log(photoUploaded.filename);
        // } else console.log("NO PHOTO");

        const form = {
            name: e.target["name"].value,
            category_id: e.target["category_id"].value,
            url: e.target["url"].value,
            content: e.target["content"].value,
        };
        if (photoUploaded) {
            form["photo"] = photoUploaded.filename;
        }
        const options = fetchOptions({
            method: "POST",
            token: this.context.token,
            body: JSON.stringify(form),
        });
        const url = `${config.api_url}recipes/`;
        try {
            const resp = await fetch(url, options);
            if (!resp.ok) {
                console.log("Status", resp.status);
                throw new Error(resp.statusText);
            }
            //const data = await resp.json();
            //console.log(data);
            this.props.history.push(`/category/${e.target["category_id"].value}`);

            // window.location = `/category/${e.target["category_id"].value}`;
        } catch (e) {
            console.error(e);
        }
    }
    render() {
       // console.log("LAST CATID:", this.context.catId);
        return (
            <div className="main">
                <h2>Add Recipe</h2>
                <RecipeForm
                    onSubmit={this.saveRecipe}
                    uploadPhoto={this.uploadPhoto}
                />
            </div>
        );
    }
}
export default withRouter(AddRecipe);
