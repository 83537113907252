import loginUser from "../../hooks/loginUser";
import { useHistory } from "react-router";
import React, { useState, useEffect } from "react";

export default function Splash(props) {
   
   
    const history =useHistory();
   
    const storedUser = props.data.user;
    const email = storedUser.email ?? "";
    const password = storedUser.password ?? "";
    const [errMsg, setErrMsg] = useState(" ");
    useEffect(()=>{
        async function checkLogin() {
            try {
                
                if (storedUser==null){

                    history.push("/login");
                    return;
                    //return(<div className="book"></div> );
                }
                
                const data = await loginUser(email,password);
                const user = {...data, 'password' : password};
                props.data.setUser(user);
                console.log(user);
                history.push("/category");
            }
            catch(err) {
                console.log("Returned err",err);
                setErrMsg(err.message);
            }
        }
        checkLogin(email,password);

    });
        


    return (
        <div className="book">
            <section>
                <h2>Preparing Cookbook</h2>
                <h3>Please Wait...</h3>
                <h6 className="error">{errMsg}</h6>
            </section>
        </div>
    )
}